import React from "react"
import SEO from '../../components/seo'

import Osirase from '../../layouts/osirase'


export default () => (


  <Osirase>

	<SEO title="プライバシーポリシー"/>

	<main id="wrapper" class="seigen">

		<h1>プライバシーポリシー</h1>
		<h2>個人情報の収集と利用目的</h2>
		<p>お問い合わせや、メールマガジンへの登録、商品のご購入などの際に、お名前やメールアドレス等の個人情報をご提供いただく場合があります。これらの情報は、ご質問に対する回答や、商品のお届け、ご希望の情報を電子メールなどでご連絡する場合など、業務上必要な範囲内に限り利用し、個人情報をご提供いただく際の目的以外では利用しません。これ以外の用途にて利用する場合は、あらかじめご本人の同意を得た上で行います。</p>
		<h2>個人情報の第三者への開示</h2>
		<p>個人情報は厳正に管理し、以下の場合を除いて第三者に提供することはありません。</p>
		<ul>
		<li>ご本人の了解がある場合。</li>
		<li>法令等への協力のために開示が必要な場合。</li>
		<li>メールマガジンの配信時等、業務の遂行上個人情報の一部を専門の第三者に委託する場合。その場合には、当該第三者について厳正な調査を行い、取り扱いを委託した個人情報の安全管理が図られるよう当該第三者に対する必要かつ適切な監督を行います。</li>
		</ul>
		<h2>個人情報の開示、訂正、追加、削除及び利用停止</h2>
		<p>ご本人から、自己の個人情報の開示、訂正、追加、削除及び利用の停止を依頼された場合は、速やかに対応いたします。その際、ご本人であることが確認できない場合には依頼に応じません。</p>
		<h2>本方針の変更</h2>
		<p>本方針の内容は当ウェブサイトに掲載した日より効力を生じるものとします。当運営者が必要と認めた場合や法令等の要請があった場合は、予告なく変更されることがあります。</p>
		<h2>アクセス解析ツールについて</h2>
		<p>アクセス解析ツール「Googleアナリティクス」を利用いたします。これは、トラフィックデータの収集のためCookieを使用します。トラフィックデータは匿名化されており、個人を特定するものではありません。この機能は、Cookieを無効にすることで収集を拒否することができますので、ご希望の場合はお使いのブラウザにて設定してください。</p>
		<h2>免責事項</h2>
		<p>当サイトのコンテンツや情報については、可能な限り正確な情報を記載するよう務めておりますが、まれに誤情報が含まれたり、情報が古くなっている場合がございます。当サイトに掲載された内容によって生じた損害等の一切の責任をおい兼ねますのでご了承ください。また、当サイトで掲載している一部の画像や動画の著作権・肖像権等は、それぞれの権利保有者に帰属します。</p>
		<h2>著作権</h2>
		<p>当サイト内の文章、画像、音声、動画等の著作物を無断転載することを禁じます。</p>

	</main>


  </Osirase>
  )